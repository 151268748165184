import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeading from '../components/pageheading'
import ContentImage from '../components/contentimage'
import {  graphql } from "gatsby"



const AdditionsAndRenovationsPage = ({data}) => (

    <Layout pageClass="info-page additions-renovations-page">
    <SEO title="Additions and Renovations" description="Modus have completed hundreds of renovations of all types of homes and experience counts." />
    <PageHeading title="Additions and Renovations" imageData={data.headerImage} />
    <main className="main">
        <div>
        <div className="typography inner reverse">
        
           
            <div className="text-holder">
                <p> 
                    Altering an existing home can be more challenging than building one from scratch. Often there are heritage features to protect, and sites with tricky access. Budgets are easily blown when demolition or digging reveals unforeseen issues. 
                </p>

                <p>
                    This is why an experienced builder with good problem-solving skills is your best option to ensure a smoothly run project.
                </p>

                <p>
                    At Modus Developments, we have completed hundreds of renovations involving homes from all eras. With data from these projects, we have developed the systems to plan effectively and cost your project accurately. We will liaise with your architect, explain our thinking and help you anticipate contingencies where necessary. By sharing our expertise, we can help you turn a tired or inadequate house into a state-of-the-art home.
                </p>

                <p className="last">
                    If you love where you live but want to add more value, Modus Developments will help you deliver your vision.
                </p>
            </div>
         
                <ContentImage image={data.contentImage} alt="Kitchen" />

        </div>
        </div>
    </main>
  </Layout>

)
export const pageQuery = graphql`
     {
        headerImage: imageSharp(resolutions: {originalName: {eq: "AE1749.jpg"}}) { 
            ...ResponsiveImage
        }
        contentImage: imageSharp(resolutions: {originalName: {eq: "The-Parade-kitchen.jpg"}}) { 
            big: fluid (maxWidth: 630, maxHeight: 590, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
            medium: fluid (maxWidth: 435, maxHeight: 785, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
            small: fluid (maxWidth: 720, maxHeight: 540, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
        }
    
    }
`

export default AdditionsAndRenovationsPage